@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600&display=swap");
header {
  padding: 1rem 2rem;
  background: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
header .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .header .logo img {
  height: 50px;
}
header .header #menu {
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
  display: none;
  z-index: 1001;
}
header .header .logo img {
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}
header .header .navbar {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .header .navbar ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
header .header .navbar ul li {
  position: relative;
  margin: 0 1rem;
}
header .header .navbar ul li a {
  font-size: 1rem;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  transition: background 0.3s ease, color 0.3s ease;
}
header .header .navbar ul li a:hover {
  background: #003d7a;
  color: #ffffff;
}
header .header .navbar ul li.dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
  border-radius: 0.3rem;
  z-index: 1000;
  text-align: center;
  align-items: center;
}
header .header .navbar ul li.dropdown .dropdown-menu li {
  margin: 0;
  text-align: center;
}
header .header .navbar ul li.dropdown .dropdown-menu li a {
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: #333333;
  transition: background 0.3s ease, color 0.3s ease;
}
header .header .navbar ul li.dropdown .dropdown-menu li a:hover {
  background: #f1f1f1;
  color: #0056b3;
}
header .header .navbar ul li.dropdown.active .dropdown-menu {
  display: block;
}

@media (max-width: 768px) {
  header .header {
    padding: 0.5rem 1rem;
  }
  header .header #menu {
    display: block;
    font-size: 1.5rem;
  }
  header .header .logo {
    margin-bottom: 1rem;
  }
  header .header .navbar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0056b3;
    padding: 1.7rem 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  header .header .navbar ul {
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  header .header .navbar ul li {
    width: 80%;
    margin: 0.1rem 0;
    background-color: #003d7a;
    border: 0.1rem solid #fff;
    border-radius: 0.3rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  header .header .navbar ul li a {
    font-size: 1.2rem;
    text-align: center;
    color: #ffffff;
  }
  header .header .navbar ul li a:hover {
    background-color: #0056b3;
  }
  header .header .navbar ul li.dropdown .dropdown-toggle::after {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }
  header .header .navbar ul li.dropdown .dropdown-menu {
    position: static;
    background: transparent;
    box-shadow: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    width: 100%;
  }
  header .header .navbar ul li.dropdown .dropdown-menu li {
    width: 100%;
    margin: 0.3rem 0;
  }
  header .header .navbar ul li.dropdown .dropdown-menu li a {
    font-size: 1rem;
    background-color: #fff;
    text-align: center;
  }
  header .header .navbar ul li.dropdown .dropdown-menu li a:hover, header .header .navbar ul li.dropdown .dropdown-menu li a:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  header .header .navbar ul li.dropdown.active .dropdown-toggle::after {
    transform: rotate(180deg);
  }
  header .header .navbar ul li.dropdown.active .dropdown-menu {
    max-height: 1000px;
  }
  header .header .navbar.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}/*# sourceMappingURL=header.css.map */