@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600&display=swap");

$primary-color: #0056b3;
$dropdown-background: #ffffff;
$dropdown-hover-color: #f1f1f1;
$hover-color: #003d7a;
$text-color: #333333;
$border-radius: 0.3rem;
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

// Header section
header {
  padding: 1rem 2rem;
  background: $primary-color;
  box-shadow: $box-shadow;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      img {
        height: 50px;
      }
    }

    #menu {
      font-size: 2rem;
      color: #ffffff;
      cursor: pointer;
      display: none;
      z-index: 1001;
    }

    .logo {
      img {
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .navbar {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
          position: relative;
          margin: 0 1rem;

          a {
            font-size: 1rem;
            color: #ffffff;
            text-decoration: none;
            padding: 0.5rem 1rem;
            border-radius: $border-radius;
            transition: background 0.3s ease, color 0.3s ease;

            &:hover {
              background: $hover-color;
              color: #ffffff;
            }
          }

          &.dropdown {
            .dropdown-menu {
              display: none;
              position: absolute;
              top: 100%;
              left: 0;
              background: $dropdown-background;
              box-shadow: $box-shadow;
              list-style: none;
              padding: 0.5rem 0;
              margin: 0;
              border-radius: $border-radius;
              z-index: 1000;
              text-align: center;
              align-items: center;

              li {
                margin: 0;
                text-align: center;

                a {
                  display: block;
                  padding: 0.5rem 1rem;
                  font-size: 0.9rem;
                  color: $text-color;
                  transition: background 0.3s ease, color 0.3s ease;

                  &:hover {
                    background: $dropdown-hover-color;
                    color: $primary-color;
                  }
                }
              }
            }

            &.active .dropdown-menu {
              display: block;
            }
          }
        }
      }
    }
  }
}

// Media queries for mobile
@media (max-width: 768px) {
  header {
    .header {
      padding: 0.5rem 1rem;

      #menu {
        display: block;
        font-size: 1.5rem;
      }

      .logo {
        margin-bottom: 1rem;
      }

      .navbar {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #0056b3;
        padding: 1.7rem 0;
        transform: translateY(-100%);
        transition: transform 0.3s ease, opacity 0.3s ease;
        opacity: 0;
        visibility: hidden;
        z-index: 1000;

        ul {
          flex-direction: column;
          padding: 0;
          margin: 0;
          width: 100%;

          li {
            width: 80%;
            margin: 0.1rem 0;
            background-color: $hover-color;
            border: 0.1rem solid #fff;
            border-radius: $border-radius;
            box-shadow: $box-shadow;

            a {
              font-size: 1.2rem;
              text-align: center;
              color: #ffffff;

              &:hover {
                background-color: $primary-color;
              }
            }

            &.dropdown {
              .dropdown-toggle::after {
                margin-left: 0.5rem;
                transition: transform 0.3s ease;
              }

              .dropdown-menu {
                position: static;
                background: transparent;
                box-shadow: none;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ease;
                width: 100%;

                li {
                  width: 100%;
                  margin: 0.3rem 0;

                  a {
                    font-size: 1rem;

                    background-color: #fff;
                    text-align: center;

                    &:hover,
                    &:active {
                      background-color: rgba($dropdown-background, 0.2);
                      color: #fff;
                    }
                  }
                }
              }

              &.active .dropdown-toggle::after {
                transform: rotate(180deg);
              }

              &.active .dropdown-menu {
                max-height: 1000px; // Adjust as needed
              }
            }
          }
        }
      }

      .navbar.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
